@tailwind base;
@tailwind components;
@tailwind utilities;


.loader { 
  display: flex;
  justify-content: center;
  margin-top: 20%;
}

@keyframes ldio-3s1v6kkaen2 {
  0% {
    top: 96px;
    left: 96px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 18px;
    left: 18px;
    width: 156px;
    height: 156px;
    opacity: 0;
  }
}

.ldio-3s1v6kkaen2 div {
  position: absolute;
  border-width: 4px;
  border-style: solid;
  opacity: 1;
  border-radius: 50%;
  animation: ldio-3s1v6kkaen2 1s cubic-bezier(0,0.2,0.8,1) infinite;
}

.ldio-3s1v6kkaen2 div:nth-child(1) {
  border-color: #1d3f72;
  animation-delay: 0s;
}

.ldio-3s1v6kkaen2 div:nth-child(2) {
  border-color: #5699d2;
  animation-delay: -0.5s;
}

.loadingio-spinner-ripple-ty7oalkdhu {
  width: 200px;
  height: 200px;
  display: inline-block;
  overflow: hidden;
}

.ldio-3s1v6kkaen2 {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}
20
.ldio-3s1v6kkaen2 div { 
  box-sizing: content-box; 
}